
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: String,
      default: 'success',
    },
  },
  setup(props, ctx) {
    const router = useRouter()

    const close = () => {
      console.log('close')
      ctx.emit('update:visible', false)
      router.back()
    }

    return {
      close,
    }
  },
})
